:root {
    --clr-primary: #8C4DE4;
    --clr-dark: rgb(24, 23, 23);
    --clr-light: rgb(250, 246, 243);
    --clr-bg: rgb(40, 44, 52);
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.App {
  text-align: center;
  /* max-width: 728px; */
  margin: 0 auto;
}

.App header {
  background-color: var(--clr-dark);
  mix-blend-mode: color-dodge;
  height: 10vh;
  min-height: 50px;
  color: var(--clr-light);
  position: fixed;
  width: 100%;
  /* max-width: 728px; */
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.App #header-img {
  content: '';
  width: 128px;
  height: 30%;
  margin-left: 1em;
  background-image: url('./logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}

.signIn {
  background-color: var(--clr-dark);
  max-width: 50%;
  margin: 0 auto;
  border-radius: 2em;
}

main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

form {
  border-radius: 2em;
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: var(--clr-dark);
  width: 100%;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  z-index: 99;
}

form button {
  background-color: #8C4DE4;
  max-height: 8vh;
  border-radius: 2em;
  align-self: center;
}


input {
  line-height: 1;
  width: 100%;
  font-size: 1.5rem;
  background: var(--clr-dark);
  color: var(--clr-light);
  outline: none;
  border: none;
  padding: 0 10px;
  border-radius: 2em;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: var(--clr-light);
  padding: 15px 24px;
  margin: 15px 15px 15px 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.sign-in {
  color: #282c34;
  background: var(--clr-light);
  max-width: 400px;
  margin: 0 auto;
}




ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: var(--clr-light);
  text-align: center;
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: var(--clr-light);
  background: var(--clr-primary);
  align-self: flex-end;
}
.received p {
  background: #181717;
  color: var(--clr-light);
}

img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
  z-index: 1;
}

@supports(mix-blend-mode: screen) {
  main::after {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(#6d21d8 0%, #26005c 50%, black 100%);
    mix-blend-mode: screen;
    pointer-events: none;
  }
}

@media screen and (min-width: 728px) {
  .messages {
    margin: 0 20%;
  }

  form {
    max-width: 80%;
  }
}
